import React, { PureComponent } from 'react'

import Article from '../components/Article'
import Button from '../components/Button'

export default class Schedule extends PureComponent {
  componentDidMount () {
    if (typeof window !== 'undefined') {
      window.location = '/ports-and-routes/schedule/'
    }
  }

  render () {
    if (typeof window !== 'undefined') {
      window.location = '/ports-and-routes/schedule/'
    }

    return (
      <Article center>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button primary to="/ports-and-routes/schedule/">
          See the schedule table
        </Button>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Article>
    )
  }
}
